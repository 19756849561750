<template>
  <div class="abnormal_data_page">
    <!-- 列表 -->
    <el-table
      :data="dataList"
      size="mini"
      border
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column type="index" label="#" align="center" width="55" />
      <el-table-column
        prop="Name"
        label="异常状态"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="Desc"
        label="描述"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column prop="Value" label="判定数值" width="200" align="center">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.editable"
            class="input-box"
            size="mini"
            v-model="scope.row.Value"
          >
            <template slot="append">{{ scope.row.Unit }}</template>
          </el-input>
          <span v-else>{{ scope.row.Value }}{{ scope.row.Unit }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        header-align="center"
        width="80"
      >
        <template slot-scope="scope">
          <i
            @click="handleProcess(scope.$index, scope.row)"
            :class="
              scope.row.editable ? 'opt fa fa-floppy-o' : 'opt el-icon-edit'
            "
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      postdata: {},
      dataList: [],
    };
  },
  computed: {},
  mounted() {
    this.netGetMessageList(); // 获取列表
  },
  methods: {
    /**
     * @description 获取列表
     */
    netGetMessageList() {
      this.$post("admin/listDetectorAbnormalRule")
        .then((res) => {
          this.dataList = res;
        })
        .catch(() => {});
    },
    handleProcess(i, row) {
      if (row.editable) {
        this.$post("admin/updateDetectorAbnormalRule", {
          Id: row.Id,
          Value: parseFloat(row.Value),
        }).then(res=>{
          this.netGetMessageList()
        })
      }
      this.$set(
        this.dataList,
        i,
        Object.assign(row, { editable: !row.editable })
      );
    },
  },
};
</script>

<style lang="scss">
.abnormal_data_page {
  .page_box {
    padding: 30px 0;
    text-align: center;
  }
}
</style>
